import axios from 'axios';

const TicketApi = {
    getMyTicket: async (token) => {
        try {
            const response = axios.get(`${process.env.API_URL}/api/v1.0/ticket/me`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            console.log('Err 💥 ', error);
        }
    },
    geTicketById: async (token, id) => {
        try {
            const response = axios.get(`${process.env.API_URL}/api/v1.0/ticket/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            console.log('Err 💥 ', error);
        }
    },
    create: async (token, payload) => {
        try {
            const response = axios.post(`${process.env.API_URL}/api/v1.0/ticket`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response;
        } catch (error) {
            console.log('Err 💥 ', error);
        }
    },
    uploadImage: async (token, file) => {
        try {
            const response = await axios.post(
                `${process.env.API_URL}/api/v1.0/ticket/upload`,
                file,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': file.type,
                    },
                }
            );
            return response;
        } catch (error) {
            return error;
        }
    },
    deleteImage: async (token, id) => {
        try {
            const response = await axios.delete(
                `${process.env.API_URL}/api/v1.0/ticket/file/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    },
}
export default TicketApi;