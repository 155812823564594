import axios from 'axios';

const ChatApi = {
  startChat: async (receiverId: string, token: string, message: string) => {
    let data = {
      receiverId,
      message
    };
    try {
      const response = await axios.post(`${process.env.API_URL}/api/v1.0/chat`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  getAllChats: async (token: string) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/chat/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  getChatMessages: async (id: string, token: string) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/chat/${id}/messages`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response;
    } catch (error) {
      return error;
    }
  },
};

export default ChatApi;
