import { ReviewType } from '@component/orders/Review';
import axios from 'axios';

const OrdersApi = {
  getOrder: async (token: string, orderId: string) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  getOrders: async (token: string, pageNumber?, pageSize?, statusId?, startDate?, endDate?) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/order/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pageNumber,
          pageSize,
          statusId,
          startDate,
          endDate,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  createOrder: async (
    token: string,
    bindingIds: Array<string>,
    buyerUserAddressId: string,
    preferredDeliveryDate: string,
    preferredDeliveryTime: string,
    deliveryNote: string
  ) => {
    let data = {
      bindingIds,
      buyerUserAddressId,
      preferredDeliveryDate,
      preferredDeliveryTime,
      deliveryNote,
    };
    try {
      const response = await axios.post(`${process.env.API_URL}/api/v1.0/order`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  makePayment: async (token, file) => {
    try {
      const response = await axios.post(
        `${process.env.API_URL}/api/v1.0/order/payment/upload`,
        file,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': file.type,
          },
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  createReview: async (token: string, orderId: string, review: ReviewType) => {
    try {
      const response = await axios.post(
        `${process.env.API_URL}/api/v1.0/rating/${orderId}`,
        review,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  getReviews: async (listingId: string) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/rating/${listingId}`);
      return response;
    } catch (error) {
      return error;
    }
  },
  getMySales: async (token: string, pageNumber?, pageSize?, statusId?, startDate?, endDate?) => {
    try {
      const response = await axios.get(`${process.env.API_URL}/api/v1.0/order/sales/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pageNumber,
          pageSize,
          statusId,
          startDate,
          endDate,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  }
};

export default OrdersApi;
