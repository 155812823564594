import axios from 'axios';

const PREFERRED_USER = 3;
const NORMAL_USER = 2;


const UserApi = {
    current: async (token) => {
        try {
            const response = await axios.get(`${process.env.API_URL}/api/v1.0/gatekeeper/me`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response;
        } catch (error) {
            return error;
        }
    },
    update: async (token, data) => {
        try {
            const response = await axios.patch(`${process.env.API_URL}/api/v1.0/user/me`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {

            return error;
        }
    },
    upload: async (token, formData) => {
        try {
            const response = await axios.post(`${process.env.API_URL}/api/v1.0/user/upload`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    ContentType: 'multipart/form-data'
                }
            })
            return response.data;
        } catch (error) {
            return error
        }
    },
    login: async (data) => {
        try {
            const response = await axios.post(`${process.env.API_URL}/api/v1.0/gatekeeper/login`, data);
            return response;
        } catch (error) {
            return null;
        }
    },
    signup: async (data) => {
        try {
            const response = await axios.post(`${process.env.API_URL}/api/v1.0/user`, data);
            return response;
        } catch (error) {
            return error;
        }
    },
    getAllUsers: async (preferred?: boolean, pageSize = 10, pageNumber = 1) => {
        try {
            const response = await axios.get(`${process.env.API_URL}/api/v1.0/user/all`, {
                params: {
                    roleId: preferred ? PREFERRED_USER : NORMAL_USER,
                    pageSize,
                    pageNumber
                }
            });
            return response;
        } catch (error) {
            return error;
        }
    },
    getUser: async (id) => {
        try {
            const response = await axios.get(`${process.env.API_URL}/api/v1.0/user/${id}`);
            return response;
        } catch (error) {
            return error;
        }
    },
    resetPassword: async (email: string, currentPassword: string, newPassword: string) => {
        try {
            const response = await axios.patch(`${process.env.API_URL}/api/v1.0/user/password`, {
                email,
                currentPassword,
                newPassword
            });
            return response;
        } catch (error) {
            return error;
        }
    }
}

export default UserApi;